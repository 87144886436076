import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Pagination from "../components/pagination"
import React, { useState, useEffect } from "react"
import Seo from "../components/seo"
import PageCategory from "./page-category"
import { updateNewsWithInterestGroup } from "../components/storyblok/newsHelper"
import Resource from "../components/resources/resource"

const NewsList = ({ data }) => {
	const [selectedCategory, setSelectedCategory] = useState("All News")
	const [filterText, setFilterText] = useState("")
	const [filteredNews, setFilteredNews] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [currentCards, setCurrentCards] = useState([])

	const pageChanged = pageNumber => {
		setCurrentPage(pageNumber)
	}

	const newsWithInterest = updateNewsWithInterestGroup(data)
	const categoriesWithName = [
		{ categoryName: "All News", catId: "All News" },
		{ categoryName: "Advocacy News", catId: "Advocacy News" },
		{
			categoryName: "Roads & Transport Directorate",
			catId: "Roads & Transport Directorate",
		},
	]
	const filteredData = newsWithInterest.filter(news =>
		news.newsWithInterestGp.includes(selectedCategory)
	)
	const cardsPerPage = 12
	const totalPages = Math.ceil(filteredNews.length / cardsPerPage)
	const startIndex = (currentPage - 1) * cardsPerPage

	useEffect(() => {
		setCurrentPage(1)
		setFilteredNews(filteredData)
	}, [selectedCategory])

	useEffect(() => {
		setCurrentPage(1)
		setFilteredNews(
			filteredData.filter(news => {
				const lowercaseFilterText = filterText.toLowerCase()
				const lowercaseName = news.name.toLowerCase()
				const lowercaseCreatedDate = news.authorizedOn
					? news.authorizedOn.toLowerCase()
					: ""
				const lowercaseAbstract = news.abstract
					? news.abstract.toLowerCase()
					: ""

				return (
					lowercaseName.includes(lowercaseFilterText) ||
					lowercaseCreatedDate.includes(lowercaseFilterText) ||
					lowercaseAbstract.includes(lowercaseFilterText)
				)
			})
		)
	}, [filterText])

	useEffect(() => {
		if (filteredNews) {
			const currentPageCards = filteredNews.slice(
				startIndex,
				startIndex + cardsPerPage
			)
			setCurrentCards(currentPageCards)
		}
	}, [filteredNews, currentPage])

	return (
		<Layout>
			<Seo title="News list" />
			<div className="mx-4">
				<PageCategory
					allCategories={categoriesWithName}
					selectedCategory={selectedCategory}
					setSelectedCategory={setSelectedCategory}
					filterText={filterText}
					setFilterText={setFilterText}
				/>
			</div>
			{currentCards.length === 0 ? (
				<p className="items-center justify-center">No single match found.</p>
			) : (
				<div className="mt-4 min-h-screen flex flex-col justify-between divide-y divide-gray-200 bg-gray-200">
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 p-3">
						{currentCards.map(item => (
							<Resource
								key={item.newsId}
								resource={item}
								useCardStyle={true}
								category={"news"}
							/>
						))}
					</div>

					<Pagination
						onPageChange={pageChanged}
						pageCount={totalPages}
						currentPage={currentPage}
						pageSize={cardsPerPage}
					/>
				</div>
			)}
			{/* <ul role="list" className="divide-y divide-gray-200 ">
				{items.map(item => (
					<li key={item.newsId} className="px-4 py-4 sm:px-0">
						<NewsListItem item={item} />
					</li>
				))}
			</ul> */}
		</Layout>
	)
}
export default NewsList

export const pageQuery = graphql`
	query NewsListQuery($skip: Int!) {
		allNewsItem(sort: { fields: authorizedOn, order: DESC }, skip: $skip) {
			nodes {
				newsId
				permalink
				name
				authorizedOn
				abstract
				thumbnailImages {
					childImageSharp {
						gatsbyImageData
					}
				}
				interestGroups {
					interestGroupId
				}
			}
		}

		allInterestGroup {
			nodes {
				interestGroupId
				name
				isRTDFilter
			}
		}
	}
`
/*
(
		<div className="bg-white shadow flex mt-5 mb-5 ml-10 mr-10 h-48 flex flex-row">
			<div className="w-full ">
				<h4 className=" w-full text-lg lg:text-lg font-bold text-neutral">
					<Link to={linkUrl}>{name}</Link>
				</h4>
				<p className="w-full my-1">
					{new Intl.DateTimeFormat("en-AU", {
						timeZone: "Australia/NSW",
						year: "numeric",
						month: "long",
						day: "2-digit",
					}).format(new Date(authorizedOn))}
				</p>
			</div>
			<div lassName="align-middle h-full overflow-hidden flex">
				<p
					c
					dangerouslySetInnerHTML={{
						__html: excerpt.slice(0, 500 || excerpt.length),
					}}
				></p>
			</div>

			{checkImg && (
				<div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
					<Link to={linkUrl}>
						<GatsbyImage
							className="h-32 w-full sm:w-32 object-cover"
							image={image}
							alt={`Thumbnail for news item`}
						/>
					</Link>
				</div>
			)}
		</div>
	)
*/
