export const updateNewsWithInterestGroup = data => {
	const newsItems = data.allNewsItem.nodes
	const allInterests = data.allInterestGroup.nodes

	const rtdGroupIds = allInterests
		.filter(interest => interest.isRTDFilter)
		.map(interest => interest.interestGroupId)
	const result = newsItems?.map(news => {
		const hasRTD = news.interestGroups.some(ig =>
			rtdGroupIds.includes(ig.interestGroupId)
		)

		const result =
			news.interestGroups.length === 0
				? ["All News"]
				: hasRTD
				? ["All News", "Roads & Transport Directorate"]
				: ["All News"]

		return {
			...news,
			newsWithInterestGp: result,
		}
	})

	return result
}
